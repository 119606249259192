/* gamesContainer.css */
/* @font-face {
  font-family: 'Delius';
  src: url('/public/Delius-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('/public/Inconsolata-Regular.ttf') format('truetype'),
       url('/public/Inconsolata-Bold.ttf') format('truetype');
  font-weight: 400, 700;
} */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: auto;
  -webkit-text-size-adjust: auto;
  text-size-adjust: auto;
}

:root {

  /* Color Palette */
    --clr-brand-1: #467696;
    --clr-accent-1: #f7d759;
    --clr-accent-2: #E0E0E0;
    --clr-light-1: #F8F6F7;
    --clr-dark-1: #2C3B64;
    --clr-danger: #b34848;
    
  /* Font family, weight and sizes */
    --ff-base: Arial, sans-serif;
    --ff-alt: Arial, sans-serif;
  
    --fw-regular: 400;
    --fw-semi-bold: 500;
    --fw-bold: 700;
  
    --shadow-thin-all: 1px 1px 2px 2px rgba(70, 118, 150, 0.3);
    --shadow-thick: 5px 5px 12px rgba(70, 118, 150, 0.3); 
    --shadow-subtle: 0 2px 5px rgba(70, 118, 150, 0.1); 
    --shadow-highlight: 1px 1px 2px 2px rgba(212, 172, 13, 0.50);
}

/* Margin and Padding Utilities */
.margin-mid {
  margin-top: 1em;
}

.margin-all {
  margin: 0.5em;
}

.margin-mini-2 {
  margin: 0.3em;
}

.margin-mini {
  margin: 0.2em;
}

.padding-mid {
  padding: 0.6em;
}

/* Full Width */
.full-width {
  width: 100%;
}

/* Font Utilities */
.small-font {
  font-weight: var(--fw-bold);
  color:var(--clr-dark-1);
  font-size: 0.7rem; /* Slightly smaller for less emphasis */
}

.mid-font {
  font-weight: var(--fw-bold);
  color:var(--clr-dark-1);
  font-size: 1rem;
}

/* Layout Utilities */
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.grid-row {
  display: contents; /* Makes each row take up full width */
  
}

.grid-row > * {
  padding: 5px; /* Adds spacing inside each grid item */
  text-align:start;
}


/* ****************Styles***************** */
body {
  font-family: var(--ff-base);
  
}

/* App Container */
.App {
  padding: 20px 30px;
  background: var(--clr-light-1); /* Clean white background */
  color: var(--clr-dark-1); /* Dark text for readability */
  height: 100vh;
  overflow: auto;
}

/* Main Games Page Styles*/
.games-container {
    text-align: center;
    padding: 20px;

  }
  
  .games-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 boxes in a row */
    gap: 15px;
    margin-top: 20px;
    justify-items: center;
  }
  
  .game-box {
    width: 200px;
    height: 200px;
    background-color: #f0f0f0;
    border: 2px solid #ddd;
    border-radius: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em !important;
    font-weight: bold;
    text-shadow: 2px 2px 1px #e78700, -2px 2px 1px #e78700, 2px -2px 1px #e78700, -2px -2px 1px #e78700, 0px 2px 1px #e78700, 0px -2px 1px #e78700, 0px 4px 1px #c96100, 2px 4px 1px #c96100, -2px 4px 1px  #c96100;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .game-box:hover {
    transform: scale(1.1);
    background-color: #f7d759;
  }
  
  .game-link {
    text-decoration: none;
    color: inherit;
    font-size: 1.2em;
  }

  /* Mobile view - single column */
@media (max-width: 820px) {
  .games-grid {
    grid-template-columns: 1fr; /* 1 column for mobile */
  }

  .game-box {
    width: 80%; /* Make the boxes take 90% of the screen width */
    height: auto; /* Let height adjust dynamically */
    aspect-ratio: 4 / 1; /* Make the height less than the width */
  }

  .game-link {
    font-size: 1.4em;
  }

}
  
  /* Wordle */
.box {
	position: relative;
  justify-items: center;
  background-color: rgba(205, 217, 224, 0.8);
  padding: 20px;
  max-width: 100%;
  
}
.wrapper {
	position: relative;
  display: flex; 
  flex-direction: column;
  gap: 0.5em;
  padding: 0.5em 0;
  border-radius: 0.5em;
  background-color: #ffffff;
  background: rgba(44, 59, 100, 0.8); /* Slight opacity for a clean look */
  box-shadow: var(--shadow-highlight);
  max-width: 25em; /* Constrain width on large screens */
  width: 100%; /* Ensures responsiveness */
  margin: 2em auto; /* Center horizontally with spacing */
  box-sizing: border-box;
}

.container {
	width: 18.75em;
	height: auto;
  position: relative;
  margin: 0 auto; /* Center the container */
  max-width: 100%;
  
}

.wordle-info {
  color: #467696;
  font-weight: bold;
}

.language-switcher, .level-switcher {
  color: #f7d759;
  font-weight: bold;
  text-align: left;  
  display: flex;
  justify-content: flex-start;
  margin: 1em;
}

.language-switch label, .level-switch label {
  margin-right: 10px; /* Space between the label and the select box */
  display: inline-block;
}

#language-switch, #level-switch {
  border-radius: 8px; /* Adjust the border-radius as per your preference */
  padding: 5px;
  background-color: #f7d759; /* Optional: Set a background color for the dropdown */
  color: #b76113; /* Ensure the text is clearly visible */
  font-weight: bold;
  border: 2px solid #b76113; /* Optional: Border around the dropdown */
  display: inline-block;
  font-family: "Delius", sans-serif;
}

.rules {
	width: 18.75em;
	height: 22.5em;
	position: relative;
}
.input-group {
	display: grid;
	width: 90%;
	grid-template-columns:  repeat(6, 1fr);
	column-gap: 0.15em;
	margin: 0.15em 0;
 
}

.input-box {
	width: 1.7em;
	height: 1.7em;
	text-align: center;
	display: block;
	border: 1px solid #000;
	font-size: 1.8em;
	line-height: 1;
	font-weight: 600;
  border-radius: 8px;
  border-color: var(--clr-brand-1);
  transition: all 0.3s ease;
	background-color: rgb(243, 237, 206);
  box-sizing: border-box;
  padding: 0;
}

.input-box:focus {
  outline: none;
  border: 4px solid var(--clr-accent-1); /* Thicker border for more prominence */
  box-shadow: 0 0 15px rgba(70, 118, 150, 0.8); /* Stronger, more intense glow */
  transform: translateY(-4px);
  transition: all 0.3s ease;
}

.input-box:disabled {
	color: #000;
}

.delete-icon-container {
  width: 1.7em;
  height: 1.7em;
  font-size: 1.8em;
  font-weight: 600;
  line-height: 1;
  transition: all 0.3s ease;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
}


.correct {
	background-color: #6aaa64;
	color: #fff;
  animation: fadeInCorrect 0.5s ease-in-out;
}

.exists {
	background-color: #c9b458;
	color: #fff;
  animation: fadeInExists 0.5s ease-in-out;
}

.incorrect {
	background-color: #787c7e;
	color: #fff;
  animation: fadeInIncorrect 0.5s ease-in-out;
}

@keyframes fadeInCorrect {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInExists {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInIncorrect {
  from { opacity: 0; }
  to { opacity: 1; }
}

.win-screen {
	font-weight: 400;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  border-radius: 0.5em;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  display: flex;
  color: #fff;
  animation: popIn 0.5s ease-out; /* Animation effect */
}

.hide {
	display: none;
}
.fab {
	color: #ff0000;
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.keyboard-container {
  position: relative; 
  width: 100%;
  max-width: 100%;
  margin: 1em auto;
}

/* Keyboard Layout */
.keyboard {
  width: 100%;
}

@media screen and (max-width: 700px) {
	.box {
		transform: translateX(-50%);
		left: 50%;
		top: 0.5em;
	}
	.wrapper {
		flex-direction: column;
	}
}
/* Style the keyboard container */
.react-simple-keyboard {
  background: rgba(44, 59, 100, 0.8) !important;
  border-radius: 10px;
  
}

/* Style individual keys */
.hg-button {
  font-family: Arial, sans-serif;
  color: #333;
  cursor: pointer;
  background-color: rgb(243, 237, 206) !important;
  font-weight: 900;
}

/* Key active effect */
.hg-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 0 #b76113, 0 4px 1px 1px rgba(0,0,0,.3);
}

/* Style the bksp and enter keys differently */
.hg-button[data-skbtn="{bksp}"], .hg-button[data-skbtn="{enter}"] {
  background: #f7d759 !important; /* Match close-button color */
}

/* Adjust the button font size and padding dynamically */
.keyboard-container .hg-button {
  font-size: var(--keyboard-font-size, 16px); /* Default to 16px if variable not set */
  padding: var(--keyboard-padding, 8px); /* Default to 8px if variable not set */
}

/* Optional: Adjust row spacing if necessary */
.keyboard-container .hg-row {
  margin-bottom: var(--keyboard-row-margin, 4px); /* Default to 4px spacing */
}

/* Style for Font Size and Button Padding labels */
.settings {
  display: flex;
  gap: 5px; /* Add a small space between the sliders */
  align-items: flex-start; /* Align labels and sliders to the top */
  margin: 5px;
  justify-content: space-between;
}

.settings label {
  font-family: Arial, sans-serif; 
  margin-bottom: 4px; /* Add a small margin below each label */
  display: flex;
  flex-direction: row; /* Stack label and slider vertically */
  align-items: flex-start; /* Align text and slider */
  color: var(--clr-brand-1);
  
}

/* Style for the sliders */
.settings input[type="range"] {
  margin-bottom: 4px; /* Small margin below sliders */
  cursor: pointer; /* Show pointer cursor on hover */
}


.popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  color: white;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000; /* Ensures it stays above other elements */
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 2s ease-in-out;
}

.game-message {
  margin-top: 20px auto;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  display: inline-block; /* Inline with the game content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/* Hangman */

@import url(https://fonts.googleapis.com/css?family=Jaro);



.input-field {
	border: 2px solid var(--clr-brand-1);
	font-family: Arial, sans-serif;
  font-size: 1.4rem;
	color: #999;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), inset 0 2px 4px rgba(255, 255, 255, 0.1);
  outline: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease-in-out;
  margin-right: 10px;
  width: 13rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
}

/* Style for the placeholder text */
.input-field::placeholder {
  font-size: 1rem; /* Smaller font size for placeholder text */
  
}

.input-field:focus {
  transform: scale(1.05); /* Slight zoom on focus */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(255, 255, 255, 0.2); /* Enhanced focus shadow */
  
}

#guess-field {
	font-family: Arial, sans-serif;
	font-size: 2.0em;
  color: var(--clr-brand-1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  font-weight:500;
}

.game-name, h2 {
	font-family: 'Jaro', sans-serif;
  font-size: 1.6rem;
  color: #f7d759;
  text-shadow: 2px 2px 1px #e78700, -2px 2px 1px #e78700, 2px -2px 1px #e78700, -2px -2px 1px #e78700, 0px 2px 1px #e78700, 0px -2px 1px #e78700, 0px 4px 1px #c96100, 2px 4px 1px #c96100, -2px 4px 1px  #c96100;
}

#mistakes-counter {
  font-family: Arial, sans-serif;
  font-size: 1.5rem;
  color: var(--clr-dark-1);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

#mistakes-bar {
  margin: 20px 0;
}

#mistakes-bar div {
  height: 20px;
  background-color: orange;
  border-radius: 5px;
  transition: width 0.3s ease;
}

#wrong-letters {
  font-family: Arial, sans-serif;
  color: var(--clr-dark-1);
  font-size: 1.2em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  
}

#wrong-letters strong {
  font-weight: bold;
}

/* Word Wander Game */
.chalkboard {
  background-color: #333; 
  border: 3px solid #222; 
  border-radius: 8px;
  padding: 0px;
  margin: 5px auto;
  width: 300px;
  color: #f7d759;
  text-align: center;
  font-family: Arial, monospace;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.chalkboard-title {
  font-size: 1.5rem;
  text-align: center;
 
  margin: 0;
  text-decoration: underline;
}

.found-words {
  font-size: 1.3em; 
  white-space: wrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  margin-top: 0;
}


.game-button {
  cursor: pointer;
  text-decoration: none !important;
  outline: none !important;
  font-family: 'Jaro', sans-serif;
  font-size: 0.8em;
  line-height: 1.5em;
  letter-spacing: .1em;
  text-shadow: 2px 2px 1px #e78700, -2px 2px 1px #e78700, 2px -2px 1px #e78700, -2px -2px 1px #e78700, 0px 2px 1px #e78700, 0px -2px 1px #e78700, 0px 4px 1px #c96100, 2px 4px 1px #c96100, -2px 4px 1px #c96100;
  border: none;
  margin: 0 1em 1em 1em;
  background: repeating-linear-gradient(45deg, #ffc800, #ffc800 5px, #ffc200 5px, #ffc200 10px);
  border-bottom: 3px solid rgba(205, 102, 0, 0.5);
  border-top: 3px solid rgba(255,255,255,.3);
  color: #fff;
  border-radius: 8px;
  padding: 6px 12px 7px;
  box-shadow: 0 6px 0 #b76113, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 3px #75421f, 0 10px 0 2px #8a542b, 0 10px 0 2px #593116, 0 10px 1px 3px rgba(0,0,0,.3); /* Alt gölge yüksekliğini daralt */
  transition: transform 0.1s ease, box-shadow 0.2s ease-in-out;
}

.game-button:hover {
  box-shadow: 0 6px 20px rgba(255, 160, 0, 0.8), 0 6px 0 #b76113, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 3px #75421f, 0 10px 0 3px #8a542b, 0 10px 0 3px #593116, 0 15px 1px 4px rgba(0, 0, 0, .3); /* Hover durumunda daha belirgin üst ışık */
}

.game-button:active {
  transform: translateY(10px); /* Button sinks in when clicked */
  box-shadow: 0 2px 0 #b76113, 0 4px 1px 1px rgba(0, 0, 0, .3), 0 6px 0 3px #75421f, 0 8px 0 3px #8a542b, 0 10px 0 3px #593116, 0 10px 1px 4px rgba(0, 0, 0, .3); /* Shadow changes to simulate press */
}


.timer {
  font-family: 'Jaro', monospace;  
  font-size: 1.5em;  
  letter-spacing: 1px;  
  color:var(--clr-danger);  
  text-shadow: 1px 1px 2px rgba(231, 42, 42, 0.6);  
}
.randoms-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
}
.randoms{ 
  background-color: rgba(205, 217, 224, 0.8);
  color: var(--clr-brand-1) !important;
  padding: 20px 15px;
  border-radius: 20px;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1), -8px -8px 15px rgba(255, 255, 255, 0.7); /* Neumorphism effect */
  font-size: 1.1rem;
  color: #333; 
  text-align: center;
}


.scoreboard {
  background-color: #333; 
  color: #f7d759; 
  font-family: 'Jaro', monospace; 
  font-size: 1.2rem; 
  padding: 0.8em 2.2em;
  text-align: center;
  border: 5px solid #f7d759; 
  border-radius: 15px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); 
  width: fit-content;
  margin: 20px auto;
  letter-spacing: .1em;
  max-width: 200px;
}

