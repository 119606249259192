/* @font-face {
  font-family: 'Delius';
  src: url('/public/Delius-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('/public/Inconsolata-Regular.ttf') format('truetype'),
       url('/public/Inconsolata-Bold.ttf') format('truetype');
  font-weight: 400, 700;
} */

.game-rules-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .game-rules-content {
    background: #333;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    text-align: left;
    box-shadow: 0 0 15px 5px rgba(249, 216, 53, 0.8);
    
  }
  
  .game-rules-content h2, .game-rules-content h3 {
    color: #f7d759;
  }
  
  .game-rules-content ul {
    list-style-type: disc;
    margin-left: 2px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
  }
  
  .game-rules-button {
    cursor: pointer;
    text-decoration: none !important;
    outline: none !important;
    font-family: 'Jaro', sans-serif;
    font-size: 1.0em;
    line-height: 1.5em;
    letter-spacing: .1em;
    text-shadow: 2px 2px 1px #e78700, -2px 2px 1px #e78700, 2px -2px 1px #e78700, -2px -2px 1px #e78700, 0px 2px 1px #e78700, 0px -2px 1px #e78700, 0px 4px 1px #c96100, 2px 4px 1px #c96100, -2px 4px 1px  #c96100;
    border: none;
    margin: 1em;
    background: repeating-linear-gradient( 45deg, #ffc800, #ffc800 5px, #ffc200 5px, #ffc200 10px);
    border-bottom: 3px solid rgba(205, 102, 0, 0.5);
    border-top: 3px solid rgba(255,255,255,.3);
    color: #fff !important;
    border-radius: 8px;
    padding: 8px 15px 10px;
    box-shadow: 0 6px 0 #b76113, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #75421f, 0 10px 0 2px #8a542b, 0 10px 0 2px #593116, 0 12px 1px 5px rgba(0,0,0,.3);
    transition: transform 0.1s ease, box-shadow 0.2s ease-in-out;
  }
  
  .game-rules-button:hover {
    box-shadow: 0 10px 20px rgba(255, 160, 0, 0.8), 0 6px 0 #b76113, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #75421f, 0 12px 0 5px #8a542b, 0 15px 0 5px #593116, 0 15px 1px 6px rgba(0, 0, 0, .3); /* Upper glow on hover */
  }
  
  .game-rules-button:active {
    transform: translateY(10px); /* Button sinks in when clicked */
    box-shadow: 0 2px 0 #b76113, 0 4px 1px 1px rgba(0, 0, 0, .3), 0 6px 0 5px #75421f, 0 8px 0 5px #8a542b, 0 10px 0 5px #593116, 0 10px 1px 6px rgba(0, 0, 0, .3); /* Shadow changes to simulate press */
  }

  .game-rules-button:disabled {
    background-color: #d3d3d3; /* Light gray background */
    cursor: not-allowed; /* Show forbidden cursor */
    opacity: 0.6; /* Slightly transparent to indicate it's disabled */
  }
  
  
  .game-rules-button::before {
    content: '';
    height: 10%;
    width: 40%;
    background: #fff;
    right: 13%;
    top: -3%;
    border-radius: 99px;
    position: relative;
    
  }
  .game-rules-button::after {
    content: '';
    height: 10%;
    width: 5%;
    background: #fff;
    right: 5%;
    top: -3%;
    border-radius: 99px;
    }
  
  .close-button {
    padding: 4px 8px 5px;
    background: repeating-linear-gradient(45deg, #ff0000, #ff0000 5px, #f10202  5px, #f10202  10px);
    text-shadow: 2px 2px 1px #9c0303, -2px 2px 1px #9c0303, 2px -2px 1px #9c0303, -2px -2px 1px #9c0303, 0px 2px 1px #9c0303, 0px -2px 1px #9c0303, 0px 4px 1px #c90000, 2px 4px 1px #c90000, -2px 4px 1px  #c90000;
    box-shadow: 0 6px 0 #b71313, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #751f1f, 0 10px 0 2px #8b2c2c, 0 10px 0 2px #591816, 0 12px 1px 5px rgba(0,0,0,.3);
  }

  .close-button:hover {
    box-shadow: 0 10px 20px rgba(255, 0, 0, 0.8), 0 6px 0 #b71313, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #75421f, 0 12px 0 5px #8a542b, 0 15px 0 5px #593116, 0 15px 1px 6px rgba(0, 0, 0, .3); /* Upper glow on hover */
  }

  .start-button {
    
    background: repeating-linear-gradient(45deg, #3edf4b, #3edf4b 5px, #03e016  5px, #03e016  10px);
    text-shadow: 2px 2px 1px #036d14, -2px 2px 1px #036d14, 2px -2px 1px #036d14, -2px -2px 1px #036d14, 0px 2px 1px #036d14, 0px -2px 1px #036d14, 0px 4px 1px #11c900, 2px 4px 1px #11c900, -2px 4px 1px  #11c900;
    box-shadow: 0 6px 0 #13b737, 0 8px 1px 1px rgba(0, 0, 0, 0.3), 0 10px 0 5px #1f752d, 0 10px 0 2px #2c8b41, 0 10px 0 2px #165927, 0 12px 1px 5px rgba(0, 0, 0, 0.3);
  }

  .start-button:hover {
    box-shadow: 0 10px 20px rgba(9, 255, 0, 0.8), 0 6px 0 #13b721, 0 8px 1px 1px rgba(0, 0, 0, 0.3), 0 10px 0 5px #1f752a, 0 12px 0 5px #3e8a2b, 0 15px 0 5px #165921, 0 15px 1px 6px rgba(0, 0, 0, 0.3); /* Upper glow on hover */
  }
  
  @media screen and (max-width: 700px) {
      .game-rules-content {
      max-width: 90%;
      max-height: 90vh; 
    overflow-y: auto;
    }
  }