/* @font-face {
  font-family: 'Delius';
  src: url('/public/Delius-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('/public/Inconsolata-Regular.ttf') format('truetype'),
       url('/public/Inconsolata-Bold.ttf') format('truetype');
  font-weight: 400, 700;
}
 */


/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

:root {

  /* Color Palette */
    --clr-brand-1: #467696;
    --clr-accent-1: #f7d759;
    --clr-accent-2: #E0E0E0;
    --clr-light-1: #F8F6F7;
    --clr-dark-1: #2C3B64;
    --clr-danger: #b34848;
    
  /* Font family, weight and sizes */
    --ff-base: Arial, sans-serif;
    --ff-alt: Arial, sans-serif;
  
    --fw-regular: 400;
    --fw-semi-bold: 500;
    --fw-bold: 700;
  
    --shadow-thin-all: 1px 1px 2px 2px rgba(70, 118, 150, 0.3);
    --shadow-thick: 5px 5px 12px rgba(70, 118, 150, 0.3); 
    --shadow-subtle: 0 2px 5px rgba(70, 118, 150, 0.1); 
    --shadow-highlight: 1px 1px 2px 2px rgba(212, 172, 13, 0.50);
}

/* Margin and Padding Utilities */
.margin-mid {
  margin-top: 1em;
}

.margin-all {
  margin: 0.5em;
}

.margin-mini-2 {
  margin: 0.3em;
}

.margin-mini {
  margin: 0.2em;
}

.padding-mid {
  padding: 0.6em;
}

/* Full Width */
.full-width {
  width: 100%;
}

/* Font Utilities */
.small-font {
  font-weight: var(--fw-bold);
  color:var(--clr-dark-1);
  font-size: 0.7rem; /* Slightly smaller for less emphasis */
}

.mid-font {
  font-weight: var(--fw-bold);
  color:var(--clr-dark-1);
  font-size: 1rem;
}

/* Layout Utilities */
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.grid-row {
  display: contents; /* Makes each row take up full width */
  
}

.grid-row > * {
  padding: 5px; /* Adds spacing inside each grid item */
  text-align:start;
}


/* ****************Styles***************** */
body {
  font-family: var(--ff-base) !important;
  background-color: var(--clr-light-1);
}

/* App Container */
.App {
  padding: 20px 30px;
  background: var(--clr-light-1); /* Clean white background */
  color: var(--clr-dark-1); /* Dark text for readability */
  height: 100vh;
  overflow: auto;
}

/* Column Container and Paper Styles*/
.main-container {
  background-color: var(--clr-light-1);
}

.paper {
  background-color: var(--clr-light-1);
  margin: 0.2em;
  box-shadow: var(--shadow-subtle);
}

/* Header Section (Header container, title, fold switch) */
.comp-header {
  align-items: center;
  border-style: solid;
  border-color: var(--clr-brand-1);
  border-width: 0.01em;
  box-shadow: var(--shadow-mid);
}

.fct-header {
  font-weight: var(--fw-bold);
  font-size: 1.2em;
  padding: 0.3em;
  margin: 0.3em;
  background-color: var(--clr-accent-2);
  color:var(--clr-dark-1);
  border-radius: 4px;
  display: block;
}

.title {
  font-weight: var(--fw-bold);
  font-size: 0.9em;
  padding-top: 0.2em;
  margin: 5px 0 1px 0 ;
  background-color: var(--clr-accent-2);
  color:var(--clr-dark-1);
  border-radius: 4px;
  display: block;
}

.switch {
  background: linear-gradient(to top, var(--clr-light-1), var(--clr-accent-2));
  font-weight: var(--fw-bold);
  font-size: 0.9em;
  padding: 0px;
  margin: 0px;
  color: var(--clr-dark-1);
  border-radius: 0px 0px 4px 4px;
  border: 2px solid #b3b3b3; /* Slightly darker metallic border */
  box-shadow: 
    2px 2px 5px rgba(0, 0, 0, 0.3), /* Bottom-right shadow */
    -2px -2px 5px rgba(255, 255, 255, 0.7); /* Top-left highlight */
  display: block; /* Ensures the button wraps around its content */
  cursor: pointer; /* Makes it look clickable */
}

.switch:hover {
  background: linear-gradient(to bottom, var(--clr-light-1), var(--clr-accent-2)); /* Reverse gradient on hover for a reflective effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2),
              inset 0 2px 3px rgba(255, 255, 255, 0.3); /* Stronger highlight on hover */
}

.switch:active {
  background-color: var(--clr-dark-1); /* Even darker background when pressed */
  border-color:  var(--clr-dark-1); /* Darker border on press */
}


/* Suggestion, Quick Access and Morpheme Boxes */
.small-box {
  cursor: pointer;
  color: var(--clr-dark-1);
  background-color: var(--clr-accent-1);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  border: 0.2em solid var(--clr-accent-1);
  box-shadow: var(--shadow-thin-all);
  font-size: 0.85em;
  font-weight: var(--fw-bold);
  transition: all 0.3s ease; /* Smooth hover transition */
  margin: 0.2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.small-box:hover {
  background-color: var(--clr-accent-2); /* Lighter hover background color */
  color: var(--clr-dark-1); /* Hover text color */
  border-color: var(--clr-dark-1);
}

.quick-access-item {
  padding: 0.3em;
}

/* Meaning and Search Area Boxes */
.meaning-block, .fieldset {
  margin:  5px;
  padding: 0.4em;
  border-width: 1px 2px 1px 2px;
  border-style: solid;
  border-radius: 10px;
  border-color: rgba(44, 59, 100, 0.2);
  background-color: var(--clr-light-1);
  transition: 0.3s;
}

.meaning-block:hover {
  transition: 1s;
  box-shadow: var(--shadow-thick);
}

#sense-container { /* Oxford Page meaning boxes*/
  max-height: 100px; 
  max-height: 150px; 
  text-overflow: ellipsis; 
  white-space: normal; 
}

/* Search Area Elements */

.edit-box {
  font-family: var(--ff-base);
  font-size: 1.1em;
  height: 1.6em;
  border: 2px solid var(--clr-dark-1);
  border-radius: 4px;
  color: var(--clr-dark-1);
  text-align: right;
  padding-right: 1px;
  width: 95%; /* Set width to 95% of the container */
  margin-right: 5px; /* Optional spacing between input and buttons */
}

.input-delete {
  position: absolute;
  right: 1%;
  top: 54%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  
  
}

.input-delete-icon img {
  width: 20px;
  height: 20px;
}

#morph-delete {
  right: 4.5%;
}

#morphWordSearch {
  padding-right: 8%;
}

/* Legend */
.legend {
  font-weight: var(--fw-bold);
  color: var(--clr-brand-1);
  padding: 10px;
}

/* Meaning Block Elements*/
.meaning-div {
  display: flex; /* Use flexbox to layout children */
  flex-wrap: nowrap; /* Prevent children from wrapping onto new lines */
  align-items: center; /* Center align items vertically */
}

.meaning-id {
  flex: 0 0 auto; /* Do not grow, do not shrink, maintain initial width */
  margin-right: 10px; /* Optional margin for spacing */
}

.meaning {
  flex: 1 1 auto; /* Grow and shrink as needed to fill available space */
  overflow: hidden; /* Hide overflow content if necessary */
  white-space: normal; /* Text wraps*/
  text-wrap: wrap;
}

.word-type {
  font-weight: var(--fw-bold);
  font-size: 0.9em;
  color: var(--clr-dark-1);
  border-radius: 8px 3px 8px 3px;
  border: 1px solid var(--clr-brand-1);
  margin: 5px;
  padding: 0.1em 0.5em 0.1em 0.5em;
}

.area-type { /* WordType component element*/
  font-weight: var(--fw-bold);
  font-size: 12px;
  color: var(--clr-dark-1);
  border: 1px solid var(--clr-brand-1);
  border-radius: 8px 3px 8px 3px;
  margin: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background-image: linear-gradient( to top, var(--clr-accent-1), var(--clr-accent-2));
}

.isim, .sıfat, .fiil, .zarf {
  background-image: linear-gradient( to top, var(--clr-light-1), var(--clr-accent-2));
  color: var(--clr-dark-1);
  font-weight: var(--fw-bold);
}

/* Lang Pocket Elements */
.meaning-lang1-cont {
  min-height: 40px;
  border-width: 1px 2px 1px 2px;
  border-radius: 10px;
  transition: 0.3s;
  border-style: "solid";
  border-color: var(--clr-dark-1);
  margin: 2px 4px 2px 4px;
  box-shadow: var(--shadow-thin-all);
  background-image: linear-gradient( to top, var(--clr-accent-2), var(--clr-light-1));
  padding: 2px;
  flex-wrap: wrap;
}

.lang-pocket {
  box-sizing: border-box;
  padding: 0.2em;
  margin: 0.2em;
  background-color: var(--clr-light-1);
  color: var(--clr-dark-1);
  user-select: none;
  border-radius: 0.3em;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12pt;
  box-shadow: var(--shadow-thin-all);
  border-width: 2px 3px 3px 2px;
  border-color: var(--clr-dark-1);
  font-family: var(--ff-alt);
  height: 20px;
  line-height: 0.4em;
}

.lang-word {
  
  font-family: var(--ff-alt);
  font-weight: var(--fw-regular);
  padding: var(--spacing-small);
}

.author {
  padding: 2px;
  margin: 2px 2px 2px 2px;
  font-size:9pt;
  color: var(--clr-dark-1);
  align-items: flex-end;
  display: flex;
  font-style: italic;
}

/* Word Morphology Component Styles */
.results-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--clr-light-1);
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: var(--shadow-thin-all);
  overflow: auto;
  margin: 0 auto;
}

.description-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto; 
}

.description-box,
.morpheme-box {
  max-width: 150px; 
  min-width: 80px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
}

.description-list {
  margin: 0;
  padding-left: 1em; 
  padding-top: 0.5em;
}

.description-item {
  list-style-type: disc; 
  margin-bottom: 5px; 
}

.divider {
  border-top: 1px solid var(--clr-dark-1);
  margin: 20px 0;
}

.plus-sign, .info-icon, .arrow {
  color: var(--clr-brand-1);
}

.info-icon {
  cursor: pointer;
}

/* Word Morphology Table style */
.table-morph {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.table-morph-row {
  display: table-row;
}

.table-morph-cell {
  display: table-cell;
  padding: 3px;
  vertical-align: middle;
  text-align: center;
}



/* QuoteFct Styles */
.quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; 
}

.open-book-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: linear-gradient(to right, transparent 30%,
  rgba(0,0,0,0.2) 46%,
  rgba(0,0,0,0.6) 49%,
  rgba(0,0,0,0.5) 50%,
  rgba(0,0,0,0.5) 51%,
  rgba(0,0,0,0.2) 52%,
  transparent 100%);
  border: 1px solid rgb(213, 211, 211);
  box-shadow: 2px var(--shadow-thin-all);
  border-radius: 5px;
}

.left-page,
.right-page {
  width: 12.5em;
  height: 16em;
  
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625em;
  position: relative; 
}

.left-page::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0; 
  width: 5px;
  height: 100%;
  border-right: 2px solid rgb(118, 116, 116);
}

.right-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0; 
  width: 5px;
  height: 100%;
  border-left: 2px solid rgb(105, 104, 104);
}

.quote-thumbnail {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.book-info {
  color: var(--clr-danger);
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  height: 100%;
}

.book-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px 0;
  border-top: 2px solid var(--clr-accent-1); 
  border-bottom: 2px solid var(--clr-accent-1); 
  padding: 5px 0; 
}

.book-publisher,
.book-year {
  font-size: 0.85rem;
  margin: 5px 0;
}

.author-name {
  font-size: 1.1rem;
  font-weight: var(--fw-bold);

}

.quote-row {
  text-align: justify;
  font-size: 1.2rem;
  font-style: italic;
  padding: 0 5%;
}
.quote-text {
  font-size: 1.1rem; /* Larger font for the quote */
  font-weight: var(--fw-bold);
  font-style: italic;
  color: var(--clr-dark-1);
  padding: 0 2%;
  border-left: 5px solid var(--clr-accent-1); /* Border on the left to emphasize the quote */
  position: relative;
  box-sizing: border-box;
}

.quote-text::before {
  content: '“'; /* Left quotation mark */
  font-size: 3rem;
  color: var(--clr-accent-1);
  position: absolute;
  top: -20px;
  left: -35px;
}

.quote-text::after {
  content: '”'; /* Right quotation mark */
  font-size: 3rem;
  color: var(--clr-accent-1);
  position: absolute;
  bottom: -25px;
  right: 10px;
}


.quote-highlight {
  position: relative;
  font-weight: bold;
  color: black; /* Text color */
  z-index: 1;
  display: inline-block;
}

.quote-highlight::before {
  content: '';
  position: absolute;
  top: -0.2em;
  left: -0.4em;
  right: -0.4em;
  bottom: -0.2em;
  background: rgb(255, 245, 101); /* A balanced orange tone */
  filter: blur(5px); /* Smudged effect */
  z-index: -1;
  border-radius: 5px; /* Rounded corners for smudge */
}


.slider-section {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 10px;
  width: 100%;
}

.report-quote {
  position: absolute;
  left: 10px; /* Position on the left side */
  top: 50%; /* Vertically centered */
  transform: translateY(-50%); /* Adjust for perfect centering */
}

.report-quote button {
  background-color: #b34848;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slider Styles */
.slider-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 16px;
}

/* Thesaurus Chain Elements*/
.tabs {
  background-color: var(--clr-light-1);
  border: var(--clr-accent-2);
}

.tab-list {
  margin: 25px 5px 5px 5px;
  padding-left: 0;
  cursor: pointer;
  border-radius: 10px 2px 10px 2px;
  border-top: 2px solid var(--clr-accent-1);
  border-right: 2px solid var(--clr-accent-1);
}

.tab-list-item {
  font-family: var(--ff-alt);
  font-weight: var(--fw-bold);
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding-left: 10px;
  padding-right: 10px;
  
}

.tab-list-active {
  background-color: var(--clr-accent-1);
  border-width: 2px 2px 0 2px;
  border-radius: 10px 2px 10px 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-color:var(--clr-accent-1);
}



/* English Library Elements */

.col-container-lang1 {
  background-color: var(--clr-light-1);
  border-color: var(--clr-dark-1);
  border-width: 5px;
  max-height: 300px;
  overflow-y: auto;
  padding: 0.6em;
}


/* Supporting Column Common Elements */
.tab-content {
  background-color: var(--clr-light-1);
  border: solid rgba(44, 59, 100, 0.2) ;
  border-width: 2px 1px 1px 1px;
  border-radius: 4px;
  padding: 0.6em;
}

.word {
  padding: 0.3em;
  margin: 0.4em;
  color: var(--clr-dark-1);
  border-radius: 7px;
  min-width: 100px;
  display: flex;
  flex-wrap: wrap;  /* Allow wrapping of the content */
  align-items: center;  /* Align items vertically centered */
  justify-content: flex-start;
  font-size: 12pt;
  font-style: italic;
  box-shadow: var(--shadow-thin-all);
  border-width: 2px 3px 3px 2px;
  border-color: var(--clr-dark-1);
  font-family: var(--ff-alt);
  font-weight: var(--fw-bold);
  white-space: normal;        /* Allow text wrapping */
  word-wrap: break-word;      /* Break long words if needed */
  height: auto;
  
}
.word > div {
  display: flex;
  align-items: center; /* Vertically align child elements */
}

.word img {
  margin-top: 6px;
  margin-left: 4px;
}

.word .lang-word {
  margin-left: 10px;
  flex-grow: 1;
}

/* Warning and Info Messages (Error & NoData) */
.message-box {
  opacity: 0.7;
  transition: opacity 0.9s ease-in-out;
  border: 1px solid var(--clr-dark-1);
  background-color: var(--clr-accent-2);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: var(--clr-dark-1);
  font-weight: var(--fw-bold);
  font-family: var(--ff-base);
}

.error-message.show,
.no-data-message.show {
  opacity: 1;
}

.error-message {
  border-color: var(--clr-danger);
  color: var(--clr-danger);
}

.no-data-message {
  border-color: var(--clr-brand-1);
  color: var(--clr-brand-1);
}

/* Modal and Overlay */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--clr-light-1);
  padding: 10px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: var(--shadow-mid);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


/* Oxford Page (modal content) elements*/
.oxford-data {
  max-width: 85%;
  max-height: 85%;
  margin: auto;
  padding:20px;
  border-radius: 8px;
  color: var(--clr-dark-1);
  font-family: var(--ff-base);
  font-size: 0.90em;
  font-weight: var(--fw-bold);   
}

.oxford-data h2 {
  font-size: 1.8em;
  text-align: center;
  color: var(--clr-dark-1);    
}

.meanings-title{
  font-size: 1.1rem;
  color: var(--clr-accent-1);
  margin-top: 5%;
  text-align: center;
}

.oxford-data div {
  margin-bottom: 5px;
}

.oxford-data strong {
  font-size: 0.90rem;
  color: var(--clr-brand-1);
}
.oxford-data audio {
  margin-top: 5px;    
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pronunciation-container {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  box-shadow: var(--shadow-thin-all);
  padding: 3px 10px 0 10px;
}

.custom-audio-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.pronunciation-text {
  font-style: italic;
  color: var(--clr-dark-1);
}

.sense-item {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 5px;
  color: var(--clr-dark-1);
  font-weight: var(--fw-bold);
  
}

.synonyms {
  white-space: normal;
  overflow-wrap: break-word;
  width: 100%;
}

.sense-item ul {
  list-style-type: disc;
  margin-left: 10px;
  padding-left: 20px;
  text-align: left;
}

.sense-item strong {
  display: block;
  margin-bottom: 3px;
  font-size: 0.90rem;
}

.etymology-container {
margin-top: 15px;
font-size: 0.90rem;
padding: 10px;
}

.examples-container, .synonyms-container {
margin-top: 10px;
}


/* Buttons */
.toggle-buttons-container {
display: flex;
justify-content: center;
margin-bottom: 10px;
align-items: center;
}

.toggle-button {
width: 86px;
padding: 0.5em 0.5em;
border-radius: 30px;
border: none;
background: var(--clr-accent-2);
color: var(--clr-brand-1);
cursor: pointer;
font-size: 0.80em;
font-weight: var(--fw-bold);
margin: 5px 10px;  
transition: all 0.3s ease; /* Smooth transition for hover effects */
box-shadow: var(--shadow-highlight);
}
.toggle-button:last-child {
margin-right: 0;
}

.toggle-button:hover {
background-color: var(--clr-accent-1);
border-color: var(--clr-accent-1);
color: var(--clr-dark-1);
}

.toggle-button-disabled { /* Disabled state */
  background-color: var(--clr-light-1);  /* Light color for disabled background */
  color: var(--clr-accent-2);  /* Gray color for disabled text */
  cursor: not-allowed;  /* Disable pointer cursor */
  box-shadow: none;  /* Remove shadow for disabled state */
  border: 1px solid var(--clr-accent-2);  /* Light border */
}

.flags-container {
  display: flex;
  align-items: center;
  
}



/* Media Queries */

@media (max-width: 600px) {
  .left-page,
  .right-page {
    width: 100%;
    margin: 0 ;
  }

  .quote-first-row {
    width: 95%;
  }
  #morphWordSearch {
    width: 40%;
  }

}

@media (max-width: 600px) {
  .edit-box-container {
    width: 50%;
    margin-right: 5px;
  }

  .edit-box {
    width: 70%;
  }

  #txtWordSearch, #morphWordSearch {
    width: 90%;
  }

  .input-delete {
    right: 5%; 
    padding-right: 7px;
  }

  #morph-delete {
    right: 6.5%; 
  }

  #morphWordSearch {
    padding-right: 15%;
  }

  .toggle-button {
    width: 76px;
    padding: 0.5em 0.2em;
    font-size: 0.70em;
    margin: 5px 7px;
    }
}

@media screen and (min-width: 1900px) {
  .input-delete {
    right: 8px; 
  }
}


@media (max-width: 768px) {
  .tutorial-popup {
   display: none !important;
}
}

@media (max-width: 600px) {
  .Modal {
    width: 95%; 
    height: auto;
    padding: 15px;
  }
}